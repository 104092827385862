<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.c-main {
  overflow: hidden;
}

.btn__modal {
  background: transparent;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid;
}
.btn__modal:hover {
  background: #000;
  color: #fff;
}

.avatar__alicia-pq {
  height: 55vh;
  margin-top: 20vh;
}

.container-alicia-pq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5vh;
}

.pq {
  width: 17em;
  height: 15em;
  background: #4b92fc;
  border-radius: 100%;
  padding: 36px 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;

  span {
    font-size: 2em;
    font-weight: bold;
  }
  p {
    color: #fff;
  }
}

.pq .triangulo-pq {
  width: 0;
  height: 0;
  border-left: 48px solid #4b92fc;
  border-top: 12px solid transparent;
  border-bottom: 47px solid transparent;
  position: relative;
  top: -5vh;
  left: 11vw;
  -webkit-transform: rotate(49deg);
  transform: rotate(49deg);
  z-index: -1;
}

.card {
  margin-bottom: 0px !important;
}

.text-content-modal {
  font-size: 1em;
}
.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}
.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

@media only screen and (max-width: 575px) {
  .avatar__alicia-pq {
    height: 45vh;
    margin-top: 13vh;
  }

  .title__section {
    font-size: 0.8em;
  }

  .pq {
    width: 12em;
    height: 11em;
    padding: 1.2em 2em;
  }

  .pq p {
    font-size: 0.8em;
  }

  .pq span {
    font-size: 1.2em;
  }

  .pq .triangulo-pq {
    top: -3vh;
    left: 26vw;
  }

  .modal.show .modal-dialog {
    max-width: 80% !important;
  }

  .text-content-modal {
    font-size: 0.8em;
    text-align: justify;
  }

  .btn__modal {
    padding: 4px 8px;
    font-size: 0.8em;
  }
}
</style>

<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
    ></loading>
    <div class="col-12 col-md-7">
      <CRow>
        <CCol col>
          <CCard>
            <CCardHeader>
              <strong>Actualización nombre o razon social</strong>
            </CCardHeader>
            <CCardBody>
              <div class="form-group col-md-10">
                <label for="">Seleccione una suscripción</label>
                <select
                  class="form-control"
                  @change="changeSuscription($event)"
                >
                  <option value="">--Seleccionar--</option>
                  <option
                    v-for="(item, index) in suscripciones"
                    :value="index + 1"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <CCard>
                <CCardHeader>
                  {{ nameSuscription }}
                  <div class="card-header-actions">
                    <a
                      href="https://coreui.io/vue/docs/components/card-components"
                      class="card-header-action"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                    </a>
                  </div>
                </CCardHeader>
                <!-- <CCardBody class="p-4">
                  <div
                    v-if="suscriptionSelected.name"
                    class="col-md-6 container__detalle"
                  >
                    <p
                      v-if="
                        suscriptionSelected.price != '' &&
                        suscriptionSelected.price != null &&
                        suscriptionSelected.price != undefined
                      "
                    >
                      <strong>Pago: </strong>{{ suscriptionSelected.price }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.periodo != '' &&
                        suscriptionSelected.periodo != null &&
                        suscriptionSelected.periodo != undefined
                      "
                    >
                      <strong>Periodo: </strong
                      >{{ suscriptionSelected.periodo }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.nextPaymentAmount != '' &&
                        suscriptionSelected.nextPaymentAmount != null &&
                        suscriptionSelected.nextPaymentAmount != undefined
                      "
                    >
                      <strong>Importe de pago pendiente: </strong
                      >{{ suscriptionSelected.nextPaymentAmount }}
                    </p>
                    <p
                      v-if="
                        suscriptionSelected.deliveryDays != '' &&
                        suscriptionSelected.deliveryDays != null &&
                        suscriptionSelected.deliveryDays != undefined
                      "
                    >
                      <strong>Dias de entrega: </strong
                      >{{ suscriptionSelected.deliveryDays }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.fechasiguienterenovacion != '' &&
                        suscriptionSelected.fechasiguienterenovacion != null &&
                        suscriptionSelected.fechasiguienterenovacion !=
                          undefined
                      "
                    >
                      <strong>Fecha de siguiente renovación: </strong
                      >{{
                        suscriptionSelected.fechasiguienterenovacion.split(
                          " "
                        )[0]
                      }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.importeultimopagocancelado != '' &&
                        suscriptionSelected.importeultimopagocancelado !=
                          null &&
                        suscriptionSelected.importeultimopagocancelado !=
                          undefined
                      "
                    >
                      <strong>Fecha de último pago cancelado: </strong
                      >{{
                        suscriptionSelected.lastPaymentDate.split(
                          " "
                        )[0]
                      }}
                    </p>

                    <p
                      v-if="
                        suscriptionSelected.lastPaymentDate != '' &&
                        suscriptionSelected.lastPaymentDate !=
                          null &&
                        suscriptionSelected.lastPaymentDate !=
                          undefined
                      "
                    >
                      <strong>Importe del último pago cancelado: </strong
                      >{{ suscriptionSelected.lastPaymentDate }}
                    </p>

                    <div
                      v-if="
                        suscriptionSelected.landing.category == 'print' ||
                        suscriptionSelected.landing.category == 'bundle'
                      "
                    >
                      <p>
                        <b>{{ firstName }}</b> .Gracias por usar nuestro
                        servicio
                      </p>
                    </div>
                    <div v-else>
                      <p>
                        <b>{{ firstName }}</b
                        >, para mayor información de tu suscripción digital.
                        !RECUERDA¡ ingresar a.
                      </p>
                      <a href="https://www.elcomercio.pe/suscripcionesdigitales"
                        >elcomercio.pe/suscripcionesdigitales</a
                      >
                    </div>

                  </div>
                </CCardBody> -->
              </CCard>

              <!-- Opciones  que pueda escojer el suscriptor -->
              <div id="opcionChange" class="form-group col-md-10">
                <label for="">Cambio a realizar</label>
                <select
                  class="form-control"
                  name=""
                  @change="selectedOpcion($event)"
                >
                  <option
                    v-for="item in arrOpcionChange"
                    :value="item.id"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <!-- Opciones 2 nombre o razon social -->
              <div id="opcionChangeDos" class="form-group col-md-10">
                <label for="">Opción</label>
                <select
                  class="form-control"
                  name=""
                  @change="selectedOpcionDos($event)"
                >
                  <option
                    v-for="item in arrOpcionDos"
                    :value="item.id"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>

              <!-- Renderizado entre la opcion dos nombre o razon social -->
              <div id="nombres">
                <!--Nombres -->
                <div class="form-group col-md-10">
                  <label>Nombres</label>
                  <p
                    v-if="isNombres"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo está vacio
                  </p>
                  <input
                    type="text"
                    class="form-control"
                    v-model="nombres"
                    @keypress="validateOnlyLetter($event)"
                    maxlength="50"
                  />
                </div>
                <!-- Apellido-->
                <div class="form-group col-md-10">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Apellido Paterno</label>
                      <p
                        v-if="isApellidoPa"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <input
                        type="text"
                        class="form-control"
                        v-model="apellidoPa"
                        @keypress="validateOnlyLetter($event)"
                        maxlength="30"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Apellido Materno</label>
                      <p
                        v-if="isApellidoMa"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <input
                        type="text"
                        class="form-control"
                        v-model="apellidoMa"
                        @keypress="validateOnlyLetter($event)"
                        maxlength="25"
                      />
                    </div>
                  </div>
                </div>
                <!-- Registrar Nombre -->
                <div class="form-group col-md-10">
                  <button
                    id="boton"
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="registraNombre()"
                  >
                    Enviar
                  </button>
                </div>
              </div>
              <div id="razonSocial">
                <!--Razon social -->
                <div class="form-group col-md-10">
                  <label>Razon social</label>
                  <p
                    v-if="isRazonSocial"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo está vacio
                  </p>
                  <input
                    type="text"
                    class="form-control"
                    v-model="razonSocial"
                    @keypress="validateOnlyLetter($event)"
                    maxlength="50"
                  />
                </div>
                <!-- Registrar razon social -->
                <div class="form-group col-md-10">
                  <button
                    id="boton"
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="registraRazonSocial()"
                  >
                    Enviar
                  </button>
                </div>
              </div>
              <!-- Renderizar cuando quiere cambiar al titular -->

              <div id="titularidad">
                <!-- titulo de datos del beneficicario -->
                <div class="form-group col-md-10">
                  <CCardHeader style="padding: 0 !important">
                    <strong> Datos del nuevo titular</strong>
                  </CCardHeader>
                  <hr />
                </div>

                <!-- //TODO: Tipo documento Y N° Documento -->
                <div class="form-group col-md-10">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Tipo documento</label>
                      <p
                        v-if="isTipoDocumento"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo tipo de documento está vacio
                      </p>
                      <select
                        v-model="tipoDocumento"
                        class="form-control"
                        style="color: black !important"
                        @change="changeTipoDocumento($event)"
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="DNI">DNI</option>
                        <option value="RUC">RUC</option>
                        <option value="Carnet de extranjeria">
                          Carnet de extranjeria
                        </option>
                        <option value="Pasaporte">Pasaporte</option>
                        <option value="CDI">CDI</option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label>N° Documento </label>
                      <p
                        v-if="isNumeroDocumento"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El campo Numero documento esta vacio
                      </p>
                      <input
                        v-model="numeroDocumento"
                        type="text"
                        class="form-control"
                        @keypress="validateNumber($event)"
                        @keyup="validateLength($event)"
                      />
                    </div>
                  </div>
                </div>

                <!--Nombres -->
                <div class="form-group col-md-10">
                  <label>Nombres</label>
                  <p
                    v-if="isNombresTitularidad"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo está vacio
                  </p>
                  <input
                    type="text"
                    class="form-control"
                    v-model="nombresTitularidad"
                    @keypress="validateOnlyLetter($event)"
                    maxlength="50"
                  />
                </div>
                <!-- Apellido-->
                <div class="form-group col-md-10">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Apellido Paterno</label>
                      <p
                        v-if="isApellidoPaTitularidad"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <input
                        type="text"
                        class="form-control"
                        v-model="apellidoPaTitularidad"
                        @keypress="validateOnlyLetter($event)"
                        maxlength="30"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Apellido Materno</label>
                      <p
                        v-if="isApellidoMaTitularidad"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo está vacio
                      </p>
                      <input
                        type="text"
                        class="form-control"
                        v-model="apellidoMaTitularidad"
                        @keypress="validateOnlyLetter($event)"
                        maxlength="25"
                      />
                    </div>
                  </div>
                </div>
                <!--Numero telefonico -->
                <div class="form-group col-md-10">
                  <label for="">Número telefónico</label>
                  <p
                    v-if="isNumeroTelefonico"
                    style="
                      color: red;
                      font-size: 0.8em;
                      margin: 0 0 5px 0;
                      padding: 0;
                    "
                  >
                    (*)El campo número telefónico está vacio
                  </p>
                  <input
                    placeholder="922513071"
                    @keypress="validateNumber($event)"
                    type="text"
                    v-model="numeroTelefonico"
                    class="form-control"
                  />
                </div>
                <!-- titulo de datos del beneficicario -->
                <div class="form-group col-md-10">
                  <hr />
                </div>
                <!-- Registrar Cambio titularidad -->
                <div class="form-group col-md-10">
                  <button
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="registrarChangeTitularidad()"
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>

    <div class="col-12 col-md-5">
      <img
        class="avatar__alicia-pq"
        v-if="flagImg == 1"
        src="../../assets/img/alicia_3.jpg"
      />
      <!--<img class="avatar__alicia__mobile-pq" src="../../assets/img/alicia_3.jpg" />-->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Cards",
  data: function () {
    return {
      suscripciones: [],
      nameSuscription: "Aún no ha seleccionado una suscripción",
      suscriptionSelected: {},
      arrOpcionDos: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "Nombre" },
        { id: "2", name: "Razón social" },
      ],
      arrOpcionChange: [
        { id: "", name: "--Seleccionar--" },
        { id: "1", name: "Cambiar el titular" },
        { id: "2", name: "El nombre registrado tiene errores" },
      ],
      show: true,
      firstName: localStorage.getItem("name").split(" ")[0].charAt(0).toUpperCase() + localStorage.getItem("name").split(" ")[0].toLowerCase().
      slice(1),
      isLoading: false,
      positionId: "",
      emailVerificado: "",
      nombreRazonSocial: "",
      opcionCambiar: "",
      fullPage: true,
      /* Nuevos datos razon social o nombre */
      isNombres: false,
      nombres: "",
      isApellidoPa: false,
      apellidoPa: "",
      isApellidoMa: false,
      apellidoMa: "",
      isRazonSocial: false,
      razonSocial: "",
      /* Fin de nuevos datos */
      /* Aactualizar cambio titualridad */
      isTipoDocumento: false,
      isNumeroDocumento: false,
      isNombresTitularidad: false,
      isApellidoPaTitularidad: false,
      isApellidoMaTitularidad: false,
      isNumeroTelefonico: false,
      tipoDocumento: "",
      numeroDocumento: "",
      numeroTelefonico: "",
      nombresTitularidad: "",
      apellidoPaTitularidad: "",
      apellidoMaTitularidad: "",
      /* fin de cambio de titularida */
      flagImg: 1,
    };
  },
  components: {
    Loading,
  },
  methods: {
    validateOnlyLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[a-zA-z ]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar numero */
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar tipo documento */
    changeTipoDocumento(event) {
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumento = id;
      vm.numeroDocumento = "";
    },
    /* TODO:validar tamaño del numero */
    validateLength(event) {
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if (vm.tipoDocumento == "DNI") len = 8;
      else if (vm.tipoDocumento == "RUC") len = 11;
      if (val.length > len) {
        vm.numeroDocumento = event.target.value.substring(0, len);
      }
    },
    /* Listar las suscripciones */
    listSubscriptions() {
      let vm = this;
      vm.isLoading = true;
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      let data = {
        document_number: localStorage.getItem("numeroDocumento"),
        document_type: localStorage.getItem("tipoDocumento"),
        optionQuery: "2",
        queryType: "1",
        optionProcess: "1",
        channel: "7",
      };
      //console.log("Send Data =>", data);
      axios
        .post(`${process.env.VUE_APP_ROOT_API}/listSuscripChatbot`, data)
        .then(function (res) {
          vm.isLoading = false;

          if (res.data.data.code == 2) {
            vm.isLoading = false;
            vm.suscripciones = [res.data.data];
            vm.emailVerificado = "";

            vm.$fire({
              title: "Felicidades",
              text: `${res.data.data.message}`,
              type: "success",
              timer: 3000,
            }).then((r) => {});
          } else {
            vm.isLoading = false;
            vm.$fire({
              title: "Seguridad",
              text: "Usted no tiene suscripciones",
              type: "info",
              timer: 3000,
            }).then((r) => {});
            vm.suscripciones = [];
          }
        })
        .catch((err) => {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: "Ocurrio un error en el servidor",
            type: "error",
            timer: 3000,
          }).then((r) => {});
        });
    },
    /* Escogiendo las suscripciones */
    async changeSuscription(event) {
      let vm = this;
      vm.positionId = event.target.value;
      vm.isLoading = true;
      vm.suscriptionSelected = {};
      vm.nameSuscription = "Aún no ha seleccionado una suscripción";

      if (vm.positionId == "") {
        vm.isLoading = false;
      } else {
        vm.isLoading = false;
        let data = {
          position_id: vm.positionId,
          consulta: "1",
          nro_documento: localStorage.getItem("numeroDocumento"),
          tipo_documento: localStorage.getItem("tipoDocumento"),
          platformOrigin: "Landing",
          queryType: "1",
        };

        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/consultaPaqueteChatbot`, data)
          .then((res) => {
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });

        //console.log("response",response);

        if (response.code == 2) {
          vm.isLoading = false;
          vm.suscriptionSelected = response;
          if (vm.suscriptionSelected.name) {
            vm.isLoading = false;
            vm.$fire({
              title: "Felicidades",
              text: `${vm.suscriptionSelected.message}`,
              type: "success",
              timer: 3000,
            }).then((r) => {});
            vm.nameSuscription = `La suscripción que ha seleccionado es: ${vm.suscriptionSelected.name}`;
            document.getElementById("opcionChange").style.display = "block";
          } else {
            vm.isLoading = false;
            vm.$fire({
              title: "info",
              text: `Suscripción no encontrada`,
              type: "info",
              timer: 3000,
            }).then((r) => {});
            vm.nameSuscription = `Aún no ha seleccionado una suscripción`;
          }
        }
      }
    },
    async selectedOpcion(event) {
      let vm = this;
      vm.opcionCambiar = event.target.value;
      //console.log(vm.positionId);

      if (vm.opcionCambiar == "") {
        vm.isLoading = false;
        vm.$fire({
          title: "Error",
          text: `Seleccione una opción`,
          type: "error",
          timer: 1000,
        }).then((r) => {});
      } else if (vm.opcionCambiar == "1") {
        //cambiar los datos de titularidad
        document.getElementById("opcionChangeDos").style.display = "none";
        document.getElementById("nombres").style.display = "none";
        document.getElementById("razonSocial").style.display = "none";
        vm.isLoading = false;

        let data = {
          position_id: vm.positionId,
          document_number: localStorage.getItem("numeroDocumento"),
          document_type: localStorage.getItem("tipoDocumento"),
          platformOrigin: "Landing",
        };

        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/validar-deuda`, data)
          .then((res) => {
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });
        //console.log("response", response);

        if (response.code == 1) {
          vm.isLoading = false;
          vm.$fire({
            title: "Felicidades",
            text: `Actualmente tu suscripción se encuentra registrada a nombre de ${response.name}, con documento de identidad ${response.documentNumber}. `,
            type: "success",
            timer: 3000,
          }).then((r) => {});
          document.getElementById("titularidad").style.display = "block";
        } else if (response.code == 2) {
          vm.isLoading = false;
          vm.$fire({
            title: "Ops!",
            text: `En breve un asesor te contactará al número que registraste. Tardará unos segundos, estate atento(a) a nuestra llamada.`,
            type: "info",
            timer: 3000,
          }).then((r) => {});
          document.getElementById("titularidad").style.display = "none";
        } else {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: `Datos ingresados incorrectamente. Por favor, ingresa 0 para volver a elegir tu suscripción en consulta.`,
            type: "error",
            timer: 3000,
          }).then((r) => {});
        }
      } else if (vm.opcionCambiar == "2") {
        document.getElementById("titularidad").style.display = "none";
        //cambiar el nombre
        let data = {
          position_id: vm.positionId,
          document_number: localStorage.getItem("numeroDocumento"),
          document_type: localStorage.getItem("tipoDocumento"),
          platformOrigin: "Landing",
        };

        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/validar-deuda`, data)
          .then((res) => {
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });
        //console.log("response", response);
        /* Validaciones */
        if (response.code == 1) {
          vm.isLoading = false;
          vm.$fire({
            title: "Felicidades",
            text: `Actualmente tu suscripción se encuentra registrada con el nombre o razón social: ${response.name}. `,
            type: "success",
            timer: 3000,
          }).then((r) => {});
          document.getElementById("opcionChangeDos").style.display = "block";
          document.getElementById("nombres").style.display = "none";
          document.getElementById("razonSocial").style.display = "none";
        } else if (response.code == 2) {
          vm.isLoading = false;
          vm.$fire({
            title: "Ops!",
            text: `En breve un asesor te contactará al número que registraste. Tardará unos segundos, estate atento(a) a nuestra llamada.`,
            type: "info",
            timer: 3000,
          }).then((r) => {});
          document.getElementById("opcionChangeDos").style.display = "none";
          document.getElementById("nombres").style.display = "none";
          document.getElementById("razonSocial").style.display = "none";
        } else {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: `Datos ingresados incorrectamente. Por favor, ingresa 0 para volver a elegir tu suscripción en consulta.`,
            type: "error",
            timer: 3000,
          }).then((r) => {});
        }
      }
    },
    /* Cuando ya escogio la opcion 2 */
    async selectedOpcionDos(event) {
      let vm = this;
      vm.nombreRazonSocial = event.target.value;

      if (vm.nombreRazonSocial == "") {
        vm.isLoading = false;
        vm.$fire({
          title: "Error",
          text: `Escoge una opción`,
          type: "error",
          timer: 1000,
        }).then((r) => {});
      } else if (vm.nombreRazonSocial == "1") {
        vm.isLoading = false;
        document.getElementById("nombres").style.display = "block";
        document.getElementById("razonSocial").style.display = "none";
      } else if (vm.nombreRazonSocial == "2") {
        document.getElementById("nombres").style.display = "none";
        document.getElementById("razonSocial").style.display = "block";
      }
    },
    async registraNombre() {
      let vm = this;

      vm.isNombres = false;
      vm.isApellidoPa = false;
      vm.isApellidoMa = false;

      if (vm.nombres == "") {
        vm.isNombres = true;
      }
      if (vm.apellidoPa == "") {
        vm.isApellidoPa = true;
      }
      if (vm.apellidoMa == "") {
        vm.isApellidoMa = true;
      }

      if (
        vm.isNombres == false &&
        vm.isApellidoPa == false &&
        vm.isApellidoMa == false
      ) {
        let vm = this;
        let data = {
          document_type: localStorage.getItem("tipoDocumento"),
          document_number: localStorage.getItem("numeroDocumento"),
          positionId: vm.positionId,
          name: vm.nombres,
          lastname: vm.apellidoPa + " " + vm.apellidoMa,
        };

        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/actualizardatos-save`, data)
          .then((res) => {
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });

        //console.log("response", response);

        if (response.code == 1) {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: `Tu suscripción está registrada como persona natural. Por lo que no es posible cambiar tu suscripción a otro tipo de documento. Te recomendarnos contactarnos vía Whatsapp al 955 559 683 o a través de clubelcomercio.pe`,
            type: "success",
            timer: 3000,
          }).then((r) => {});
          vm.nombres = "";
          vm.apellidoPa = "";
          vm.apellidoMa = "";
          vm.isNombres = false;
          vm.isApellidoPa = false;
          vm.isApellidoMa = false;
        } else if (response.code == 2) {
          vm.isLoading = false;
          vm.$fire({
            title: "Ops",
            text: `Los datos ingresados son los mismos que están registrados actualmente.`,
            type: "info",
            timer: 3000,
          }).then((r) => {});
          vm.nombres = "";
          vm.apellidoPa = "";
          vm.apellidoMa = "";
          vm.isNombres = false;
          vm.isApellidoPa = false;
          vm.isApellidoMa = false;
        } else if (response.code == 3) {
          vm.isLoading = false;
          vm.$fire({
            title: "Ops",
            text: `${vm.firstName}, cuentas con una solicitud en proceso de cambio de nombre o razón social.`,
            type: "info",
            timer: 3000,
          }).then((r) => {});
          vm.nombres = "";
          vm.apellidoPa = "";
          vm.apellidoMa = "";
          vm.isNombres = false;
          vm.isApellidoPa = false;
          vm.isApellidoMa = false;
        } else {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: `Datos ingresados incorrectamente`,
            type: "error",
            timer: 3000,
          }).then((r) => {});
        }
      }
    },
    async registraRazonSocial() {
      let vm = this;
      vm.isRazonSocial;

      if (vm.razonSocial == "") {
        vm.isRazonSocial = true;
      }
      if (vm.isRazonSocial == false) {
        let vm = this;
        let data = {
          document_type: localStorage.getItem("tipoDocumento"),
          document_number: localStorage.getItem("numeroDocumento"),
          positionId: vm.positionId,
          name: "",
          lastname: vm.razonSocial,
        };
        const response = await axios
          .post(`${process.env.VUE_APP_ROOT_API}/actualizardatos-save`, data)
          .then((res) => {
            vm.isLoading = false;
            return res.data.data;
          })
          .catch((err) => {
            vm.isLoading = false;
            return err;
          });

        if (response.code == 1) {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: `Tu suscripción está registrada como persona natural. Por lo que no es posible cambiar tu suscripción a otro tipo de documento. Te recomendarnos contactarnos vía Whatsapp al 955 559 683 o a través de clubelcomercio.pe`,
            type: "success",
            timer: 3000,
          }).then((r) => {});
          vm.razonSocial = "";
          vm.isRazonSocial = false;
        } else if (response.code == 2) {
          vm.isLoading = false;
          vm.$fire({
            title: "Ops",
            text: `Los datos ingresados son los mismos que están registrados actualmente.`,
            type: "info",
            timer: 3000,
          }).then((r) => {});
          vm.razonSocial = "";
          vm.isRazonSocial = false;
        } else if (response.code == 3) {
          vm.isLoading = false;
          vm.$fire({
            title: "Ops",
            text: `Tienes una solicitud pendiente de cambio de nombre o razón social, vuelve a intentarlo en 24 horas.`,
            type: "info",
            timer: 3000,
          }).then((r) => {});
          vm.razonSocial = "";
          vm.isRazonSocial = false;
        } else {
          vm.isLoading = false;
          vm.$fire({
            title: "Error",
            text: `Datos ingresados incorrectamente`,
            type: "error",
            timer: 3000,
          }).then((r) => {});
        }
      }
    },
    /* Para hacer el cambio de titularidad */
    async registrarChangeTitularidad() {
      let vm = this;

      vm.isNombresTitularidad = false;
      vm.isApellidoPaTitularidad = false;
      vm.isApellidoMaTitularidad = false;

      if (vm.tipoDocumento == "") {
        vm.isTipoDocumento = true;
      }

      if (vm.numeroDocumento == "") {
        vm.isNumeroDocumento = true;
      }
      if (vm.nombresTitularidad == "") {
        vm.isNombresTitularidad = true;
      }
      if (vm.apellidoPaTitularidad == "") {
        vm.isApellidoPaTitularidad = true;
      }
      if (vm.apellidoMaTitularidad == "") {
        vm.isApellidoMaTitularidad = true;
      }
      if (vm.numeroTelefonico == "") {
        vm.isNumeroTelefonico = true;
      }

      if (
        vm.isTipoDocumento == false &&
        vm.isNumeroDocumento == false &&
        vm.isNombresTitularidad == false &&
        vm.isApellidoPaTitularidad == false &&
        vm.isApellidoMaTitularidad == false
      ) {
        /* Validacion del numero telefonico */
        if (
          vm.numeroTelefonico.length != 7 &&
          vm.numeroTelefonico.length != 9
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe contener 7 u 9 números.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else if (
          vm.numeroTelefonico.length == 9 &&
          vm.numeroTelefonico.charAt(0) != "9"
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe comenzar con el dígito 9.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else {
          let data = {
            document_number: localStorage.getItem("numeroDocumento"),
            document_type: localStorage.getItem("tipoDocumento"),
            positionId: vm.positionId,
            documentNumber: vm.numeroDocumento,
            documentType: vm.tipoDocumento,
            phone: vm.numeroTelefonico,
            firstname: vm.nombresTitularidad,
            lastname: vm.apellidoPaTitularidad + " " + vm.apellidoMaTitularidad,
            optionDirection: "2",
          };
          const response = await axios
            .post(
              `${process.env.VUE_APP_ROOT_API}/cambiotitularidad-save`,
              data
            )
            .then((res) => {
              vm.isLoading = false;
              return res.data.data;
            })
            .catch((err) => {
              vm.isLoading = false;
              return err;
            });

          //console.log("response", response);

          if (response.code == 1) {
            vm.isLoading = false;
            vm.$fire({
              title: "¡Gracias por la información!",
              html: `<p>${response.name}, para concluir con el cambio de titularidad es necesario que ingreses a este enlace : </p> <a href=${response.url}>Aqui</a> .`,
              type: "success",
              timer: 3000,
            }).then((r) => {});
            vm.nombresTitularidad = "";
            vm.apellidoPaTitularidad = "";
            vm.apellidoMaTitularidad = "";
            vm.numeroTelefonico = "";
            vm.isNombresTitularidad = false;
            vm.isApellidoPaTitularidad = false;
            vm.isApellidoMaTitularidad = false;
            vm.isNumeroTelefonico = false;

          }else if(response.code == 2){
            vm.isLoading = false;
            vm.$fire({
              title: "Ops",
              text: `Los datos ingresados son los mismos que están registrados actualmente.`,
              type: "info",
              timer: 3000,
            }).then((r) => {});
            vm.nombresTitularidad = "";
            vm.apellidoPaTitularidad = "";
            vm.apellidoMaTitularidad = "";
            vm.numeroTelefonico = "";
            vm.isNombresTitularidad = false;
            vm.isApellidoPaTitularidad = false;
            vm.isApellidoMaTitularidad = false;
            vm.isNumeroTelefonico = false;
          }else if(response.code == 3){
            vm.isLoading = false;
            vm.$fire({
              title: "Ops",
              text: `${vm.firstName}, cuentas con una solicitud en proceso de cambio de nombre o razón social.`,
              type: "info",
              timer: 3000,
            }).then((r) => {});
            vm.nombresTitularidad = "";
            vm.apellidoPaTitularidad = "";
            vm.apellidoMaTitularidad = "";
            vm.numeroTelefonico = "";
            vm.isNombresTitularidad = false;
            vm.isApellidoPaTitularidad = false;
            vm.isApellidoMaTitularidad = false;
            vm.isNumeroTelefonico = false;
          }else{
            vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: ` Datos ingresados incorrectamente.`,
              type: "error",
              timer: 2500,
            }).then((r) => {});
            vm.nombresTitularidad = "";
            vm.apellidoPaTitularidad = "";
            vm.apellidoMaTitularidad = "";
            vm.numeroTelefonico = "";
            vm.isNombresTitularidad = false;
            vm.isApellidoPaTitularidad = false;
            vm.isApellidoMaTitularidad = false;
            vm.isNumeroTelefonico = false;
          }

        
        }
      }
    },

    showImg() {
      this.flagImg = 1;
    },
    hideImg() {
      this.flagImg = 0;
    },
  },
  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    vm.listSubscriptions();
    document.getElementById("opcionChange").style.display = "none";
    document.getElementById("opcionChangeDos").style.display = "none";
    document.getElementById("nombres").style.display = "none";
    document.getElementById("razonSocial").style.display = "none";
    document.getElementById("titularidad").style.display = "none";
  },
};
</script>
